<template>
  <div class="q-w360 q-fit">
    <question-with-error-wrap
      :error="getFieldsError(['environmentalAllergies', 'otherEnvironmentalAllergies'])"
    >
      <multi-answer-question
        title="label.environmentalAllergies"
        :value="environmentalAllergies"
        :other-value="otherEnvironmentalAllergies"
        :options="$options.environmentalAllergiesOptions"
        @input="onFieldChange('environmentalAllergies', $event)"
        @other-input="onFieldChange('otherEnvironmentalAllergies', $event)"
      />
    </question-with-error-wrap>
  </div>
</template>

<script>
import QuestionWithErrorWrap from '@/modules/questionnaire/new-design-components/questions/QuestionWithErrorWrap';
import MultiAnswerQuestion from '@/modules/questionnaire/new-design-components/questions/multi-answer-question/MultiAnswerQuestion';

import { stepTemplateMixin } from '@/modules/questionnaire/mixins/stepTemplateMixin';

import {
  ENVIRONMENTAL_ALLERGY,
  OTHER_VALUE_INCLUSIVE_BINDINGS
} from '@/modules/questionnaire/api/constants';

const ENVIRONMENTAL_ALLERGIES_OPTIONS = [
  { value: ENVIRONMENTAL_ALLERGY.POLLEN, text: 'environmentalAllergy.pollen' },
  { value: ENVIRONMENTAL_ALLERGY.CATS_OR_DOGS, text: 'environmentalAllergy.catsOrDogs' },
  { value: ENVIRONMENTAL_ALLERGY.SUN, text: 'environmentalAllergy.sun' },
  { value: ENVIRONMENTAL_ALLERGY.INSECT_STINGS, text: 'environmentalAllergy.insectStings' },
  { value: ENVIRONMENTAL_ALLERGY.MOLD, text: 'environmentalAllergy.mold' },
  { value: ENVIRONMENTAL_ALLERGY.DUST_MITES, text: 'environmentalAllergy.dustMites' },
  { ...OTHER_VALUE_INCLUSIVE_BINDINGS, text: 'environmentalAllergy.other' }
];

export default {
  name: 'EnvironmentalAllergiesTemplate',
  components: { MultiAnswerQuestion, QuestionWithErrorWrap },
  mixins: [stepTemplateMixin],
  environmentalAllergiesOptions: ENVIRONMENTAL_ALLERGIES_OPTIONS,
  props: {
    environmentalAllergies: {
      type: Array,
      required: true
    },
    otherEnvironmentalAllergies: {
      type: String,
      required: true
    }
  }
};
</script>
